body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer base {
  @font-face {
      font-family: "Gotham";
      src: url("../src/shared/fonts/Gotham-Light.otf");
      font-display: swap;
  }
  @font-face {
    font-family: "GothamBold";
    src: url("../src/shared/fonts/Gotham-Bold.otf");
    font-display: swap;
}
  @font-face {
    font-family: "PTSerif";
    src: url("../src/shared/fonts/PTSerif-Regular.otf");
    font-display: swap;
  }
}

div.alice-carousel > ul.alice-carousel__dots {
  position: absolute;
  margin: 0 auto !important;
  padding: 0;
  bottom: -25px;
  width: 100%;
}
.alice-carousel__dots-item.__active{
  background: #000000 !important;
}

.alice-carousel__dots-item {
  background: #00000057 !important;
}
.rsw-btn {
  border: none;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn{
  padding: 0!important;
  display: block!important;
}
@media (max-width:720px) {
  div.alice-carousel > ul.alice-carousel__dots {
      position: absolute;
      margin: 0 auto !important;
      padding: 0;
      bottom: -20px;
      width: 100%;
  }
}

@media (max-width:400px) {
  div.alice-carousel > ul.alice-carousel__dots {
      position: absolute;
      margin: 0 auto !important;
      padding: 0;
      bottom: -25px;
      width: 100%;
  }
}